/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "global/components/DashboardNavbar";
import Footer from "global/components/Footer";

// Dashboard components
import DefaultCard from "examples/Cards/InfoCards/DefaultCard";
import MDTypography from "components/MDTypography";
import RegistroPaquete from "./components/registroPaquete";
import { useAuth } from "context/AuthContext";
import Calculadora from "./components/calculadora";

function Dashboard() {
  const { currentUser, currentName } = useAuth();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox p={{ xs: 1, md: 3 }}>
        <Grid container spacing={4}>
          <Grid sx={{ width: {}, marginBottom: { xs: "30px" } }} item xs={12} md={6}>
            <DefaultCard color="dark" icon="flight" title="Dirección - Aérea">
              <div style={{ textAlign: "left", color: "white", minHeight: "180px" }}>
                <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                  <MDTypography
                    sx={{
                      typography: { md: "body2", xs: "caption" },
                      width: "80px",
                    }}
                    mx="10px"
                    fontWeight="regular"
                  >
                    Name:
                  </MDTypography>
                  <MDTypography
                    sx={{ typography: { md: "body2", xs: "caption" } }}
                    mx="10px"
                    fontWeight="regular"
                  >
                    Racecar Imports ({currentName})
                  </MDTypography>
                </div>
                <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                  <MDTypography
                    sx={{ typography: { md: "body2", xs: "caption" }, width: "80px" }}
                    mx="10px"
                    fontWeight="regular"
                  >
                    Address:
                  </MDTypography>
                  <MDTypography
                    sx={{ typography: { md: "body2", xs: "caption" } }}
                    mx="10px"
                    fontWeight="regular"
                  >
                    11350 NW 25 ST SUITE 100
                  </MDTypography>
                </div>
                <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                  <MDTypography
                    sx={{ typography: { md: "body2", xs: "caption" }, width: "80px" }}
                    mx="10px"
                    fontWeight="regular"
                  >
                    City:
                  </MDTypography>
                  <MDTypography
                    sx={{ typography: { md: "body2", xs: "caption" } }}
                    mx="10px"
                    fontWeight="regular"
                  >
                    DORAL, FLORIDA
                  </MDTypography>
                </div>
                <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                  <MDTypography
                    sx={{ typography: { md: "body2", xs: "caption" }, width: "80px" }}
                    mx="10px"
                    fontWeight="regular"
                  >
                    State:
                  </MDTypography>
                  <MDTypography
                    sx={{ typography: { md: "body2", xs: "caption" } }}
                    mx="10px"
                    fontWeight="regular"
                  >
                    Florida (FL)
                  </MDTypography>
                </div>
                <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                  <MDTypography
                    sx={{ typography: { md: "body2", xs: "caption" }, width: "80px" }}
                    mx="10px"
                    fontWeight="regular"
                  >
                    Zip Code:
                  </MDTypography>
                  <MDTypography
                    sx={{ typography: { md: "body2", xs: "caption" } }}
                    mx="10px"
                    fontWeight="regular"
                  >
                    33172
                  </MDTypography>
                </div>
                <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                  <MDTypography
                    sx={{ typography: { md: "body2", xs: "caption" }, width: "80px" }}
                    mx="10px"
                    fontWeight="regular"
                  >
                    Phone:{" "}
                  </MDTypography>
                  <MDTypography
                    sx={{ typography: { md: "body2", xs: "caption" } }}
                    mx="10px"
                    fontWeight="regular"
                  >
                    305-477-5508
                  </MDTypography>
                </div>
              </div>
            </DefaultCard>
          </Grid>
          <Grid item xs={12} md={6}>
            <DefaultCard color="dark" icon="directions_boat" title="Dirección - Marítima">
              <div style={{ textAlign: "left", color: "white" }}>
                <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                  <MDTypography
                    sx={{
                      typography: { md: "body2", xs: "caption" },
                      width: "80px",
                    }}
                    mx="10px"
                    fontWeight="regular"
                  >
                    Name:
                  </MDTypography>
                  <MDTypography
                    sx={{ typography: { md: "body2", xs: "caption" } }}
                    mx="10px"
                    fontWeight="regular"
                  >
                    {currentName}
                  </MDTypography>
                </div>
                <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                  <MDTypography
                    sx={{ typography: { md: "body2", xs: "caption" }, width: "80px" }}
                    mx="10px"
                    fontWeight="regular"
                  >
                    Address 1:
                  </MDTypography>
                  <MDTypography
                    sx={{ typography: { md: "body2", xs: "caption" } }}
                    mx="10px"
                    fontWeight="regular"
                  >
                    8425 NW 68th ST
                  </MDTypography>
                </div>
                <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                  <MDTypography
                    sx={{ typography: { md: "body2", xs: "caption" }, width: "80px" }}
                    mx="10px"
                    fontWeight="regular"
                  >
                    Address 2:
                  </MDTypography>
                  <MDTypography
                    sx={{ typography: { md: "body2", xs: "caption" } }}
                    mx="10px"
                    fontWeight="regular"
                  >
                    Race001966
                  </MDTypography>
                </div>

                <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                  <MDTypography
                    sx={{ typography: { md: "body2", xs: "caption" }, width: "80px" }}
                    mx="10px"
                    fontWeight="regular"
                  >
                    City:
                  </MDTypography>
                  <MDTypography
                    sx={{ typography: { md: "body2", xs: "caption" } }}
                    mx="10px"
                    fontWeight="regular"
                  >
                    Miami
                  </MDTypography>
                </div>
                <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                  <MDTypography
                    sx={{ typography: { md: "body2", xs: "caption" }, width: "80px" }}
                    mx="10px"
                    fontWeight="regular"
                  >
                    State:
                  </MDTypography>
                  <MDTypography
                    sx={{ typography: { md: "body2", xs: "caption" } }}
                    mx="10px"
                    fontWeight="regular"
                  >
                    Florida (FL)
                  </MDTypography>
                </div>
                <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                  <MDTypography
                    sx={{ typography: { md: "body2", xs: "caption" }, width: "80px" }}
                    mx="10px"
                    fontWeight="regular"
                  >
                    Zip Code:
                  </MDTypography>
                  <MDTypography
                    sx={{ typography: { md: "body2", xs: "caption" } }}
                    mx="10px"
                    fontWeight="regular"
                  >
                    33166
                  </MDTypography>
                </div>
                <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                  <MDTypography
                    sx={{ typography: { md: "body2", xs: "caption" }, width: "80px" }}
                    mx="10px"
                    fontWeight="regular"
                  >
                    Phone:{" "}
                  </MDTypography>
                  <MDTypography
                    sx={{ typography: { md: "body2", xs: "caption" } }}
                    mx="10px"
                    fontWeight="regular"
                  >
                    305-801-1339
                  </MDTypography>
                </div>
              </div>
            </DefaultCard>
          </Grid>
        </Grid>
      </MDBox>
      <Grid justifyItems="center" container spacing={2}>
        <Grid item xs={0} md={4}>
          <Calculadora />
        </Grid>
        <Grid item xs={12} md={8}>
          <RegistroPaquete />
        </Grid>
      </Grid>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
